<template>
  <div
    class="stds-dialog items-start pointer-events-auto min-w-[128rem] bg-[var(--stds-sem-color-background-variant-1)]"
  >
    <component :is="currentComponent" />
  </div>
</template>
<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue';
import { useRoute } from 'vue-router';

import { ServiceUnavailable } from '@/enums/service-unavailable.enum';
import { definePageMeta } from '#imports';

definePageMeta({
  layout: 'empty'
});

const componentsFactory: Record<string, ReturnType<typeof defineAsyncComponent>> = {
  [ServiceUnavailable.AGE_RESTRICTION]: defineAsyncComponent(
    () => import('@/components/service-unavailable/age-restriction/index.vue')
  )
};

const route = useRoute();
const errorType = route.query.errorType as string;
const currentComponent: any = ref<ReturnType<typeof defineAsyncComponent>>(
  componentsFactory[errorType]
);
</script>
